<template>
  <b-row>
    <b-col class="chat">
      <PageHeader :title="party?.name || ''">
        <div class="messages-header-button__button-wrapper">
          <button
            type="button"
            @click="() => {}"
            class="messages-header-button__button"
          >
            <span
              class="page-chat__icon-wrapper page-chat__icon-wrapper--header"
            >
              <CIcon name="search" class="messages-header-button__icon" />
            </span>
          </button>

          <tippy
            interactive
            placement="bottom-center"
            distant="7"
            trigger="mouseenter"
          >
            <template #trigger>
              <span
                class="page-chat__icon-wrapper page-chat__icon-wrapper--header"
              >
                <CIcon
                  name="h-dots"
                  path="messages"
                  class="messages-header-button__icon"
                />
              </span>
            </template>

            <div class="page-chat__tooltip">
              <button
                type="button"
                class="page-chat__button page-chat__button--tooltip"
              >
                <span class="page-chat__font page-chat__font--tooltip">
                  {{ $t("general.postComponent.tooltip.copyLink") }}
                </span>
              </button>

              <button
                type="button"
                class="page-chat__button page-chat__button--tooltip"
              >
                <span class="page-chat__font page-chat__font--tooltip">
                  {{ $t("general.postComponent.tooltip.report") }}
                </span>
              </button>

              <button
                type="button"
                class="page-chat__button page-chat__button--tooltip"
              >
                <span class="page-chat__font page-chat__font--tooltip">
                  {{ $t("general.postComponent.tooltip.edit") }}
                </span>
              </button>
            </div>
          </tippy>
        </div>
      </PageHeader>

      <b-row class="conversation" ref="conversation">
        <div class="wrapper">
          <div v-for="(message, index) in messages" :key="index">
            <div
              class="timeline"
              v-if="isShowTimline(messages, message, index)"
            >
              <div class="timeline__text">
                <span class="timeline__font">{{ $t(message.dayRelated) }}</span>
              </div>
            </div>

            <ChatMessages
              :message="message"
              :key="messages.length"
              @messageChange="updateMessages"
            />
          </div>
        </div>
      </b-row>

      <b-row>
        <b-col class="py-2 border-top">
          <ui-media-uploader
            :key="media.length"
            v-model="media"
            ref="uploader"
            @change="mediaChange"
          />
        </b-col>
        <div class="user-chat-input">
          <div class="user-chat-input__input" v-if="true">
            <CTextarea
              name="message"
              v-model.trim="message"
              theme="comments"
              @blur="updateCursorPosition"
              :placeholder="$t('general.chat.placeholder')"
            />
          </div>
        </div>

        <div>
          <small v-if="errors.has('price')" class="text-danger p-2">
            {{ errors.first("price") }}
          </small>
        </div>

        <div class="page-chat__footer">
          <input
            ref="videoInput"
            type="file"
            :accept="mediaTypes"
            @change="uploadFromDevice"
            hidden
          />

          <button
            type="button"
            @click="mediaPopup"
            class="button page-chat__button page-chat__button--setting"
          >
            <span class="page-chat__icon-wrapper">
              <CIcon name="image" path="messages" class="page-chat__icon" />
            </span>
          </button>

          <!--          @click="tip"-->
          <button
            v-if="isCreatorRole"
            type="button"
            v-b-modal="'modalPrice'"
            class="button page-chat__button page-chat__button--setting"
          >
            <span class="page-chat__icon-wrapper">
              <CIcon name="tip" path="messages" class="page-chat__icon" />
            </span>
          </button>

          <button
            type="button"
            @click.prevent="sendMessage"
            class="base-button page-chat__button page-chat__button--send"
            :class="{ 'page-chat__button--disabled': !message }"
          >
            <span class="base-button__font">{{ $t("general.send") }}</span>
          </button>
        </div>

        <b-modal
          id="modalPrice"
          centered
          hide-footer
          header-class="flow-modal__header"
          :title="$t('general.message-price')"
        >
          <b-input-group>
            <CInput
              type="text"
              name="price"
              input-mode="text"
              theme="comments"
              v-model="price"
              v-mask="currencyMask"
              :custom-errors="errors"
              :placeholder="$t('general.free')"
            />
          </b-input-group>
        </b-modal>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Message from "@/components/models/Message";
import UiMediaUploader from "@/features/containers/post-create/components/UiMediaUploader.vue";
import User from "@/components/models/User";
import PageHeader from "@/layout/PageHeader.vue";
import CIcon from "@/features/ui/CIcon.vue";
import dayjs from "dayjs";
import ChatMessages from "@/features/containers/messages/ChatMessages.vue";
import CTextarea from "@/features/ui/CTextarea.vue";
import uploadFromDevice from "@/mixins/uploadFromDevice";
import Payment from "@/components/models/Payment";
import { mapMutations } from "vuex";
import CInput from "@/features/ui/СInput.vue";
import validationError from "@/mixins/validationError";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
export default {
  name: "PageChat",
  mixins: [uploadFromDevice, validationError],
  components: {
    CInput,
    CTextarea,
    ChatMessages,
    CIcon,
    PageHeader,
    UiMediaUploader,
  },
  props: {
    value: Array,
  },
  data() {
    return {
      messages: [],
      price: null,
      message: "",
      party: null,
      page: 1,
      hasMore: false,
      isLoading: false,
      addedManually: 0,
      // chatId: null,
      chats: [],
      cursorPosition: 0,
    };
  },
  watch: {
    pusher() {
      this.listen();
    },
  },
  computed: {
    currencyMask() {
      return createNumberMask({
        // prefix: "$",
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
      });
    },

    chatId() {
      return this.$route.params.id && this.$route.params.id != "compose"
        ? this.$route.params.id * 1
        : null;
    },
    isCreatorRole() {
      return this.$store.state.currentUser.role.includes(User.ROLE_CREATOR);
    },
    currency() {
      return process.env.VUE_APP_CURRENCY_SIGN;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    pusher() {
      return this.$store.state.pusher;
    },
  },
  mounted() {
    this.initState();
  },
  beforeDestroy() {
    this.unlisten();
  },
  methods: {
    ...mapMutations({
      setTempTip: "payments/setTempTip",
    }),

    initState() {
      this.init();
      this.setupMuxInput(this.$refs.videoInput);
      this.$refs.conversation.addEventListener("scroll", this.updateScroll);
    },

    updateCursorPosition(textarea) {
      this.cursorPosition = textarea.selectionStart;
    },

    updateMessages(currentMessage) {
      const listDataIndex = this.messages?.findIndex(
        (item) => item?.id === currentMessage.id
      );

      if (listDataIndex === -1) return;

      this.messages[listDataIndex] = { ...currentMessage };
    },

    listen() {
      if (this.pusher) {
        this.pusher.bind("message", this.handleNewMessage);
        this.pusher.bind("message.paid", this.handlePaidMessage);
      }
    },
    unlisten() {
      if (this.pusher) {
        this.pusher.unbind("message", this.handleNewMessage);
        this.pusher.bind("message.paid", this.handlePaidMessage);
      }
    },

    handleNewMessage(data) {
      const newMessage = new Message(data.message);
      if (newMessage.user.id == this.chatId) {
        this.messages.unshift(newMessage);
      }
    },

    handlePaidMessage(data) {
      const message = this.messages?.find(message => message.id === data.message_id)

      const listDataIndex = this.messages?.findIndex(
        (item) => item?.id === message.id
      );

      if (listDataIndex === -1) return;

      message.is_paid = true;
      message.isRead = true;
      this.messages[listDataIndex] = { ...message };
    },

    init() {
      this.chatId = this.$route.params.id;

      this.messages = [];
      this.price = null;
      this.message = "";
      this.media = [];
      this.party = null;
      let chats = [...this.chats];
      for (let c of chats) {
        if (c?.party?.id == this.chatId) {
          this.party = c.party;
          c.message.isRead = true;
        }
      }
      this.page = 1;
      this.hasMore = false;
      this.isLoading = false;
      this.chats = chats;
      this.loadMessages();

      this.listen();
    },
    createdAt(time) {
      return dayjs(time).fromNow();
    },
    isShowTimline(messages, message, index) {
      return (
        index == messages.length - 1 ||
        (index < messages.length - 1 &&
          !messages[index + 1].created_at.isSame(message.created_at, "day"))
      );
    },
    updateScroll() {
      if (
        this.$refs.conversation &&
        this.$refs.conversation.scrollTop <= 200 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    readMessages() {
      for (let m of this.messages) {
        m.isRead = true;
      }
    },
    loadMessages(page) {
      this.isLoading = true;
      this.$get(
        "/messages/" + this.chatId + "?page=" + (page ? page : this.page),
        (data) => {
          const recent =
            this.messages.length > 0 ? this.messages[0].created_at : null;
          parser: for (let obj of data.messages.data) {
            const m = new Message(obj);
            for (let mm of this.messages) {
              if (mm.id == m.id) {
                continue parser;
              }
            }
            if (!recent || m.created_at.isBefore(recent)) {
              this.messages.push(m);
            } else {
              this.addMessage(m);
            }
          }
          this.isLoading = false;
          if (!page) {
            this.hasMore = data.messages.next_page_url != null;
          }
          this.party = this.party ? this.party : new User(data.party);
          if (this.page == 1) {
            this.$nextTick(function () {
              this.$refs.conversation.scrollTo({
                top: this.$refs.conversation.scrollHeight,
                behavior: "instant",
              });
            });
          }
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    reloadFirst() {
      this.loadMessages(1);
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        if (this.addedManually >= 20) {
          this.page = this.page + 1;
          this.addedManually = 0;
        }
        this.loadMessages();
      }
    },
    mediaDropzoneClean() {
      console.log("clean");
      this.$refs.uploader?.clean();
    },
    priceFormat() {
      return this.currency + this.price;
    },
    priceRemove() {
      this.price = null;
    },
    addMessage(message) {
      this.messages.unshift(message);
      if (message.user.id != this.$store.state.currentUser.id) {
        message.isRead = true;
      }
      let valid = [];
      let chats = [...this.chats];
      let found = false;
      for (let c of chats) {
        if (c.party.id == this.party.id) {
          c.message = message;
          found = true;
          valid.unshift(c);
        } else {
          valid.push(c);
        }
      }
      if (!found) {
        valid.unshift({
          party: this.party,
          message: message,
        });
      }

      this.chats = valid;
      this.$nextTick(function () {
        this.$refs.conversation.scrollTo({
          top: this.$refs.conversation.scrollHeight,
          behavior: "smooth",
        });
      });

      this.addedManually++;
    },
    sendMessage() {
      if (!this.message) return;

      this.$validator.errors.clear();

      let media = [];
      for (let m of this.media) {
        media.push({
          id: m.id,
          screenshot: m.scr ? m.scr.id : null,
        });
      }

      let fields = {
        message: this.message,
      };
      if (media.length > 0) {
        fields.media = media;
      }

      const price = this.price?.replace("$", "")?.replaceAll(",", "") ?? 0;
      if (price) {
        fields.price = price;
      }
      this.message = "";

      this.$post(
        "/messages/" + this.party.id,
        fields,
        (data) => {
          let message = new Message(data);
          this.addMessage(message);
          this.message = "";
          this.media = [];
          this.price = null;
          this.mediaDropzoneClean();
        },
        (errors) => {
          this.errors = errors;
        },
        this.checkErrors
      );
    },

    tip() {
      this.setTempTip({ ...this.currentPost, tipType: Payment.TYPE_MESSAGE });
      this.$popup.open("TipPopup");
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/vendors/_variables.scss";

textarea.form-control {
  border: none;
  scroll-behavior: smooth;
}
textarea.form-control:focus {
  border: none;
  box-shadow: none;
}
.chat {
  min-height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  flex-direction: column;
  display: flex;
  .conversation {
    @include hideScroll();
    overflow-y: auto;
    overscroll-behavior: contain;
    flex: 1;
    display: flex;
    .wrapper {
      width: 100% !important;
      margin-top: auto;
      flex-direction: column-reverse;
      display: flex;
    }
  }
  .timeline {
    position: relative;
    display: flex;
    justify-content: center;
    &::before {
      content: "";
      position: absolute;
      height: 1px;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: $app-gray-2;
    }

    &__text {
      position: relative;
      z-index: 5;
      padding: 0 $spacer;
      background: $white;
    }

    &__font {
      color: $app-font-color-gray-2;
      font-family: $font-default;
      font-size: em(12);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
@include media-breakpoint-down(xs) {
  .chat {
    min-height: calc(var(--vh, 1vh) * 100 - (#{$spacer} * 4));
    max-height: calc(var(--vh, 1vh) * 100 - (#{$spacer} * 4));
  }
}

.page-chat {
  &__footer {
    display: flex;
    align-items: center;
    width: 100%;
    margin: em(0) em(16) em(12);
  }

  &__icon-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: em(20);
    height: em(20);

    &--header {
      width: em(24);
      height: em(24);
      margin-left: em(16);
    }
  }

  &__icon {
    color: $black;
    width: em(17);
    height: em(17);
  }

  &__tooltip {
    padding: em(18) em(18) em(20);
  }

  &__button {
    &--setting {
      width: auto;

      &:not(:first-child) {
        margin-left: em(13);
      }
    }

    &--send {
      margin-left: auto;
    }

    &--tooltip {
      color: $black;
      text-decoration: none;
      justify-content: flex-start;

      &:not(:first-child) {
        margin-top: em(15);
      }

      &:active {
        transform: none;
        filter: none;
      }
    }

    &--disabled {
      pointer-events: none;
      filter: grayscale(80%);
    }
  }
}

.messages-header-button {
  &__button-wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-start;
  }

  &__button {
    width: em(30);
    text-align: center;
  }

  &__icon {
    color: $app-gray-17;
    width: em(19);
    height: em(19);

    &--plus {
      width: em(14);
      height: em(14);
    }
  }
}

.user-chat-input {
  display: flex;
  border-top: 1px solid $app-gray-2;
  width: 100%;
  background-color: white;

  &__avatar-wrapper {
    margin-top: em(24);
    margin-right: em(13);
    margin-left: em(14);
  }

  &__avatar {
  }

  &__input {
    margin: em(17) em(14) em(14);
    flex: 1;
  }

  &__icon {
    color: $app-gray-6;
    width: em(19);
    height: em(19);
  }
}

.base-button {
  $parent: &;
  width: auto;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 24) em(15, 24);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
}
</style>
